import video1 from "../../assets/videos/1.mp4"
import video2 from "../../assets/videos/2.mp4"
import video3 from "../../assets/videos/3.mp4"
import video4 from "../../assets/videos/4.mp4"
import video5 from "../../assets/videos/5.mp4"
import video6 from "../../assets/videos/6.mp4"
import video7 from "../../assets/videos/7.mp4"
import video8 from "../../assets/videos/8.mp4"
import video9 from "../../assets/videos/9.mp4"



const Videos = [
    {
        id: 1,
        title: 'Video 1',
        url: video1,
    },
    {
        id: 2,
        title: 'Video 2',
        url: video2,
    },
    {
        id: 3,
        title: 'Video 3',
        url: video3,
    },
    {
        id: 4,
        title: 'Video 4',
        url: video4,
    },
    {
        id: 5,
        title: 'Video 5',
        url: video5,
    },
    {
        id: 6,
        title: 'Video 6',
        url: video6,
    },
    {
        id: 7,
        title: 'Video 7',
        url: video7,
    },
    {

        id: 8,
        title: 'Video 8',
        url: video8,
    },
    {
        id: 9,
        title: 'Video 9',
        url: video9,
    }
];

export default Videos;